<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="rolekanban">
        <heade></heade>
        <txa></txa>
        <div class="rolekanban_box">
            <h6>角色看板</h6>
            <div class="rolekanban_box_tit">
                <ul>
                    <li v-for="(i,index) in sj_list" :key="index" @click="dangqian_num=index" :class="dangqian_num==index?'xuanzhong':''">
                        <img :src="dangqian_num==index?require('../../assets/me/xuan_zi.png'):require('../../assets/me/weixuan_zi.png')" alt="">
                        {{i.name}}
                    </li>
                    <li @click="zhunze_dakai=true">角色规则</li>
                    <!-- <li class="xianshi_shezhi" @click="xianshi_shezhi=true">显示设置</li> -->
                    <li>单位：{{danwei==1?'元':(danwei==2?'万元':'亿元')}}</li>
                </ul>
                <div class="rolekanban_box_tit_right">
                    <div class="rolekanban_box_tit_right_l" v-if="zhuguan_liyuan||bumen_zhuguan||kan_quanbu">
                        <div class="rolekanban_box_tit_right_l1"></div>
                        筛选部门/人员
                        <div class="rolekanban_box_tit_right_box">
                            <div class="rolekanban_box_tit_right_box_con">
                                <el-input
                                    placeholder="请输入搜索内容"
                                    class="kehu_sousuo_renyuan"
                                    v-model="bumen_renyuan_text">
                                </el-input>

                                    <!-- :filter-node-method="filterNode"  获取当前node -->
                                    <!--  -->
                                    <!-- :filter-node-method="bumen_tree_guolv" -->
                                    <!-- default-expand-all   是否全部展开字节-->
                                <el-tree
                                    class="filter-tree kehu_tree"
                                    :data="bumen_renyuan_tree"
                                    :props="fuzi_guanlian"
                                    node-key='id'
                                    show-checkbox
                                    highlight-current
                                    @check-change="dianji_bumen_shu"
                                    :filter-node-method="bumen_tree_guolv"
                                    ref="kehu_bumen_ren_tree">
                                </el-tree>
                            </div>
                        </div>
                    </div>
                    <div class="rolekanban_box_tit_right_l">
                        <div class="rolekanban_box_tit_right_l1"></div>
                        筛选产品
                        <div class="rolekanban_box_tit_right_box">
                            <div class="rolekanban_box_tit_right_box_con">
                                <el-input
                                    placeholder="请输入搜索内容"
                                    class="kehu_sousuo_renyuan"
                                    v-model="chanpin_text">
                                </el-input>

                                    <!-- :filter-node-method="filterNode"  获取当前node -->
                                    <!-- default-expand-all  是否展开字节 -->
                                <el-tree
                                    class="filter-tree kehu_tree"
                                    :data="chanpin_tree"
                                    :props="fuzi_guanlian"
                                    ref="kehu_chanpin_tree"
                                    show-checkbox
                                    node-key='id'
                                    highlight-current
                                    check-strictly
                                    @check-change="dianji_chanpin_shu"
                                    :filter-node-method="chanpin_tree_guolv"
                                    >
                                    <!-- @check='fuxuan' -->
                                </el-tree>
                            </div>
                        </div>
                    </div>
                    <p class="rolekanban_box_tit_right_2" @click="dongtai_chaxun">确定</p>
                </div>
            </div>
            <ul class="rolekanban_box_sousuo_list">
                <li v-for="(i,index) in suoxuan_bumen_list" :key="index"><i @click="dianji_shanchu_bumen_suoxuan(i)" class="el-icon-error"></i>{{i.label}}</li>
                <li v-for="(i,index) in suoxuan_list" :key="index"><i @click="dianji_shanchu_suoxuan(i)" class="el-icon-error"></i>{{i.label}}</li>
            </ul>
            <el-table
                :data="laogao_zhongji"
                row-key="id"
                lazy
                :load="load"
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                height='6.2rem'
                :header-cell-style="biaotou"
                ref="laogao_zhongji_tree"
                min-width='100%'>
                <el-table-column
                    prop="label"
                    label="组织架构"
                    min-width="130"
                    fixed
                    >
                </el-table-column>
                <el-table-column v-if="zhanshi_kehu">
                    <template slot="header">
                        <p class="laogao_zhongji_tit">客户<span>(act)</span></p>
                    </template>
                    <el-table-column
                        label="新增/已分配数量"
                        width="150">
                        <template slot-scope="props">
                            <div class="laogao_zhongji_box">
                                <span :class="props.row.cust.status_add=='0'?'dian_red':(props.row.cust.status_add=='1'?'dian_yellow':(props.row.cust.status_add=='2'?'dian_green':''))"></span>
                                {{props.row.cust.num_cust_add}}
                                /
                                {{props.row.cust.num_cust_alloted}}
                                <span :class="props.row.cust.status_alloted=='0'?'dian_red':(props.row.cust.status_alloted=='1'?'dian_yellow':(props.row.cust.status_alloted=='2'?'dian_green':''))"></span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column v-if="zhanshi_baifang" label="拜访">
                    <el-table-column
                        label="次数/工作日">
                        <template slot-scope="props">
                            <div class="laogao_zhongji_box">
                                <span :class="props.row.visit.status_num_visit=='0'?'dian_red':(props.row.visit.status_num_visit=='1'?'dian_yellow':(props.row.visit.status_num_visit=='2'?'dian_green':''))"></span>
                                {{props.row.visit.num_visit}}
                                /
                                {{props.row.visit.workday}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="时长（h）"
                        width="80">
                        <template slot-scope="props">
                            <div class="laogao_zhongji_box">
                                {{props.row.visit.visit_long}}
                                <span :class="props.row.visit.status_visit_long=='0'?'dian_red':(props.row.visit.status_visit_long=='1'?'dian_yellow':(props.row.visit.status_visit_long=='2'?'dian_green':''))"></span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column v-if="zhanshi_loudou">
                    <template slot="header">
                        <p class="laogao_zhongji_tit">漏斗<span>(act)</span></p>
                    </template>
                    <el-table-column
                        label="数量/金额"
                        width="140">
                        <template slot-scope="props">
                            <div class="laogao_zhongji_box">
                                {{props.row.funn.num_funn}}
                                /
                                {{zhanshi_shuzi(props.row.funn.amt_funn)}}
                                <span :class="props.row.funn.status_amt_funn=='0'?'dian_red':(props.row.funn.status_amt_funn=='1'?'dian_yellow':(props.row.funn.status_amt_funn=='2'?'dian_green':''))"></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="折算金额/折算比率"
                        width="150">
                        <template slot-scope="props">
                            <div class="laogao_zhongji_box">
                                {{zhanshi_shuzi(props.row.funn.amt_funn_disc)}}
                                /
                                {{(Number(props.row.funn.rate_funn_disc)*100).toFixed(2)+'%'}}
                                <span :class="props.row.funn.status_rate_funn_disc=='0'?'dian_red':(props.row.funn.status_rate_funn_disc=='1'?'dian_yellow':(props.row.funn.status_rate_funn_disc=='2'?'dian_green':''))"></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="转化数量/比率"
                        width="130">
                        <template slot-scope="props">
                            <div class="laogao_zhongji_box">
                                <span :class="props.row.funn.status_num_funn_trsl=='0'?'dian_red':(props.row.funn.status_num_funn_trsl=='1'?'dian_yellow':(props.row.funn.status_num_funn_trsl=='2'?'dian_green':''))"></span>
                                {{props.row.funn.num_funn_trsl}}
                                /
                                {{(Number(props.row.funn.rate_num_funn_trsl)*100).toFixed(2)+'%'}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="转化金额/比率"
                        width="150">
                        <template slot-scope="props">
                            <div class="laogao_zhongji_box">
                                <span :class="props.row.funn.status_amt_funn_trsl=='0'?'dian_red':(props.row.funn.status_amt_funn_trsl=='1'?'dian_yellow':(props.row.funn.status_amt_funn_trsl=='2'?'dian_green':''))"></span>
                                {{zhanshi_shuzi(props.row.funn.amt_funn_trsl)}}
                                /
                                {{(Number(props.row.funn.rate_amt_funn_trsl)*100).toFixed(2)+'%'}}
                            </div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column v-if="zhanshi_hetong" label="合同">
                    <el-table-column
                        label="合同数量/合同金额"
                        width="248">
                        <template slot-scope="props">
                            <div class="laogao_zhongji_box">
                                {{props.row.con.num_con}}
                                /
                                {{zhanshi_shuzi(props.row.con.amt_con)}}
                                <div class="tiao_xing_tu">
                                    <p>{{zhanshi_shuzi(props.row.con.amt_con)}}</p>
                                    <div>
                                        <p class="tiao_zong"></p>
                                        <p class="tiao_mubiao" :style="props.row.aim_total!=0&&(Number(props.row.con.amt_con)/Number(props.row.aim_total))<=1?'width:'+(Number(props.row.con.amt_con)/Number(props.row.aim_total)*100).toFixed(0)+'px;':'width:100px;'"></p>
                                    </div>
                                    <p>{{props.row.aim_total!=0?(Number(props.row.con.amt_con)/Number(props.row.aim_total)*100).toFixed(2)+'%':'100%'}}</p>
                                </div>

                                <span :class="props.row.con.status_amt_con=='0'?'dian_red':(props.row.con.status_amt_con=='1'?'dian_yellow':(props.row.con.status_amt_con=='2'?'dian_green':''))"></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width='132'>
                        <template slot="header">
                            <p class="laogao_zhongji_tit">未开票金额<span>(act)</span></p>
                        </template>
                        <template slot-scope="props">
                            <div class="laogao_zhongji_box">
                                <div class="annulusBasics">
                                    <div class="centerCircle">
                                        {{zhanshi_shuzi(props.row.con.amt_un_inv)}}
                                        <br />
                                        {{props.row.con.amt_con!=0?(Number(props.row.con.amt_un_inv)/Number(props.row.con.amt_con)*100).toFixed(0)+'%':'100%'}}
                                    </div>
                                    <div class="annulusOuter"></div>
                                    <div class="leftRectangle" :style="props.row.con.amt_con!=0?
                                    (Number((Number(props.row.con.amt_un_inv)/Number(props.row.con.amt_con)*100).toFixed(0))*3.6>180?
                                    'transform:rotate('+(Number((Number(props.row.con.amt_un_inv)/Number(props.row.con.amt_con)*100).toFixed(0))*3.6-180)+'deg)':''):
                                    'background:#5dd5ff'"></div> 
                                    <div class="rightRectangle" :style="props.row.con.amt_con!=0?
                                    (Number((Number(props.row.con.amt_un_inv)/Number(props.row.con.amt_con)*100).toFixed(0))*3.6>180?
                                    'background:#5dd5ff':'transform:rotate('+Number((Number(props.row.con.amt_un_inv)/Number(props.row.con.amt_con)*100).toFixed(0))*3.6+'deg)'
                                    ):'background:#5dd5ff'"></div>
                                    <div className={style.repairAnnulus}></div>
                                </div>
                                <span :class="props.row.con.status_amt_un_inv=='2'?'dian_red':(props.row.con.status_amt_un_inv=='1'?'dian_yellow':(props.row.con.status_amt_un_inv=='0'?'dian_green':''))"></span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column v-if="zhanshi_fapiao" label="发票">
                    <el-table-column
                        label="金额"
                        width='186'>
                        <template slot-scope="props">
                            <div class="laogao_zhongji_box">
                                <div class="tiao_xing_tu">
                                    <p>{{zhanshi_shuzi(props.row.inv.amt_inv)}}</p>
                                    <div>
                                        <p class="tiao_zong"></p>
                                        <p class="tiao_mubiao2" :style="props.row.aim_total!=0&&(Number(props.row.inv.amt_inv)/Number(props.row.aim_total))<=1?'width:'+(Number(props.row.inv.amt_inv)/Number(props.row.aim_total)*100).toFixed(0)+'px;':'width:100px;'"></p>
                                    </div>
                                    <p>{{props.row.aim_total!=0?(Number(props.row.inv.amt_inv)/Number(props.row.aim_total)*100).toFixed(2)+'%':'100%'}}</p>
                                </div>
                                <span :class="props.row.inv.status_amt_inv=='0'?'dian_red':(props.row.inv.status_amt_inv=='1'?'dian_yellow':(props.row.inv.status_amt_inv=='2'?'dian_green':''))"></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="130">
                        <template slot="header">
                            <p class="laogao_zhongji_tit">应收金额<span>(act)</span></p>
                        </template>
                        <template slot-scope="props">
                            <div class="laogao_zhongji_box">
                                <div class="annulusBasics">
                                    <div class="centerCircle">
                                        {{zhanshi_shuzi(props.row.inv.amt_un_rec)}}
                                        <br />
                                        {{props.row.inv.amt_inv!=0?(Number(props.row.inv.amt_un_rec)/Number(props.row.inv.amt_inv)*100).toFixed(0)+'%':'100%'}}
                                    </div>
                                    <div class="annulusOuter2"></div>
                                    <!--  :style="props.row.target_mount!=0?'transform:rotate('+Number((Number(props.row.fapiao.yingshou_mount)/Number(props.row.target_mount)*100).toFixed(0))*3.6+'deg)':'transform:rotate(0deg)'" -->
                                    <div class="leftRectangle" :style="props.row.inv.amt_inv!=0?(
                                        Number((Number(props.row.inv.amt_un_rec)/Number(props.row.inv.amt_inv)*100).toFixed(0))*3.6>180?
                                        'transform:rotate('+(Number((Number(props.row.inv.amt_un_rec)/Number(props.row.inv.amt_inv)*100).toFixed(0))*3.6-180)+'deg)':''
                                        ):'background:#66cccc;'"></div> 
                                    <div class="rightRectangle" :style="props.row.inv.amt_inv!=0?(
                                        Number((Number(props.row.inv.amt_un_rec)/Number(props.row.inv.amt_inv)*100).toFixed(0))*3.6>180?
                                        'background:#66cccc;':'transform:rotate('+Number((Number(props.row.inv.amt_un_rec)/Number(props.row.inv.amt_inv)*100).toFixed(0))*3.6+'deg)')
                                        :'background:#66cccc;'"></div>
                                    <div className={style.repairAnnulus}></div>
                                </div>
                                <span :class="props.row.inv.status_amt_un_rec=='2'?'dian_red':(props.row.inv.status_amt_un_rec=='1'?'dian_yellow':(props.row.inv.status_amt_un_rec=='0'?'dian_green':''))"></span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column v-if="zhanshi_huikuan" label="回款">
                    <el-table-column
                        label="金额"
                        width="175">
                        <template slot-scope="props">
                            <div class="laogao_zhongji_box">
                                <div class="tiao_xing_tu">
                                    <p>{{zhanshi_shuzi(props.row.rec.amt_rec)}}</p>
                                    <div>
                                        <p class="tiao_zong"></p>
                                        <p class="tiao_mubiao3" :style="props.row.aim_total!=0&&(Number(props.row.rec.amt_rec)/Number(props.row.aim_total))<=1?'width:'+(Number(props.row.rec.amt_rec)/Number(props.row.aim_total)*100).toFixed(0)+'px;':'width:100px;'"></p>
                                    </div>
                                    <p>{{props.row.aim_total!=0?(Number(props.row.rec.amt_rec)/Number(props.row.aim_total)*100).toFixed(2)+'%':'100%'}}</p>
                                </div>
                                <span :class="props.row.rec.status_amt_rec=='0'?'dian_red':(props.row.rec.status_amt_rec=='1'?'dian_yellow':(props.row.rec.status_amt_rec=='2'?'dian_green':''))"></span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column v-if="zhanshi_paigong" label="派工">
                    <el-table-column
                        label="次数/人数">
                        <template slot-scope="props">
                            <div class="laogao_zhongji_box">
                                <span :class="props.row.dis.status_num_dis=='0'?'dian_red':(props.row.dis.status_num_dis=='1'?'dian_yellow':(props.row.dis.status_num_dis=='2'?'dian_green':''))"></span>
                                {{props.row.dis.num_dis}}
                                /
                                {{props.row.dis.num_dis_user}}
                            </div>
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table>
            <div class="rolekanban_beijing" v-if="zhunze_dakai">
                <div class="rolekanban_beijing_box">
                    <i @click="zhunze_dakai=false" class="el-icon-error"></i>
                    <p>角色规则</p>
                    <p style="font-size:0.12rem;text-align:right;">单位：元</p>
                    <el-table :data="neirong_list"
                        border
                        height="5.8rem"
                        :header-cell-style="biaotou"
                        :cell-style="liebiao"
                        class="lao_gao_zhong_ji_guize"
                        >
                        <!-- height="4.4rem" -->
                        <el-table-column v-for="(i, ind) in biaotou_list"
                            :key="ind"
                            :prop="i.con"
                            :label="i.name">
                            <template slot-scope="props">
                                <span><span v-if="props.row.bitian&&i.con=='label'" :style="props.row.bitian?'color:red;':''">*</span>{{props.row[i.con]}}</span>
                                <!-- <input v-model="props.row[i.con]" @keyup="props.row.label.indexOf('率')!='-1'?bilv(props.row,i.con):wuyong()" :placeholder="props.row.label.indexOf('率')!='-1'?'请输入（1-100）数字':'请输入'" v-if="props.row[i.con]!=undefined&&i.con!='label'" type="text"> -->
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <div class="beijing" v-if="xianshi_shezhi" @click="xianshi_shezhi=false">
            <div class="beijing_box" @click.stop>
                <p class="beijing_box_tit">显示设置</p>
                <div class="beijing_box_con">
                    <div>
                        <p class="beijing_box_con_tit">选择：</p>
                        <p class="beijing_box_con_quanbu" @click="dianji_xuanze_quanbu"><img :src="xianshi_quanbu?require('../../assets/okblue.png'):require('../../assets/noccc.png')">全部</p>
                        <ul class="renyuan_xuanze_list">
                            <li class="renyuan_xuanze_list_li" v-for="(i,index) in zhanshi_list" :key="index" @click="xuanze_danxuan(i)">
                                <img class="xuanxiang_kuang" :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')">{{i.name}}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p class="beijing_box_con_tit">显示项：</p>
                        <ul class="renyuan_xuanze_list">
                            <li class="renyuan_xuanze_list_li" v-for='(i,index) in xianshi_xiang_list' :key="index">
                                {{i.name}}<img class="shanchu_xianshi" @click="dianji_shanchu_xianshi(i,index)" src="../../assets/guanbi_zhu.png">
                            </li>
                        </ul>
                    </div>
                </div>
                <p class="beijing_box_foot">
                    <span @click="dianji_lie_queding">确定</span>
                    <span @click="xianshi_shezhi=false">取消</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import txa from '../../components/Txa'
import { query_user_info, query_dept_staff_tree_aim, query_role_board_rule, generate_role_board, query_ent_prod_list_tree, no_sale_prod_list, query_dept_staff_tree_aim_not } from '../../api/api.js'
import heade from './gongzuohead'
export default {
  name: 'rolekanban',
  data () {
    return {
      dangqian_num: '0',
      sj_list: [
        {
          name: '日',
          id: '1'
        },
        {
          name: '周',
          id: '2'
        },
        {
          name: '月',
          id: '3'
        },
        {
          name: '季',
          id: '4'
        },
        {
          name: '年',
          id: '5'
        }
      ],
      bumen_renyuan_text: '',
      bumen_renyuan_tree: [],
      fuzi_guanlian: {
        children: 'children',
        label: 'label'
      },
      chanpin_text: '',
      chanpin_tree: [],
      suoxuan_list: [],
      suoxuan_bumen_list: [],
      laogao_zhongji: [],
      kehu: false,
      loudou: false,
      baifang: false,
      biaotou_list: [
        {
          name: '指标',
          con: 'label'
        },
        {
          name: '日',
          con: 'r'
        },
        {
          name: '周',
          con: 'zhou'
        },
        {
          name: '月',
          con: 'yue'
        },
        {
          name: '季',
          con: 'ji'
        },
        {
          name: '年',
          con: 'nian'
        }
      ],
      neirong_list: [
        {
          label: '客户新增数量',
          r: ''
        },
        {
          label: '客户已分配数量',
          r: ''
        },
        {
          label: '拜访次数',
          r: '',
          zhou: '',
          yue: '',
          ji: '',
          nian: ''
        },
        {
          label: '单次拜访时长',
          r: '',
          zhou: '',
          yue: '',
          ji: '',
          nian: ''
        },
        {
          label: '漏斗金额',
          r: ''
        },
        {
          label: '漏斗折算比率',
          r: ''
        },
        {
          label: '漏斗转化数量',
          r: ''
        },
        {
          label: '漏斗转化金额',
          r: ''
        },
        {
          label: '合同完成率',
          r: '',
          zhou: '',
          yue: '',
          ji: '',
          nian: '',
          bitian: true
        },
        {
          label: '发票完成率',
          r: '',
          zhou: '',
          yue: '',
          ji: '',
          nian: '',
          bitian: true
        },
        {
          label: '未开票比率',
          r: '',
          bitian: true
        },
        {
          label: '回款完成率',
          r: '',
          zhou: '',
          yue: '',
          ji: '',
          nian: '',
          bitian: true
        },
        {
          label: '应收占比',
          r: '',
          bitian: true
        },
        {
          label: '派工单数量',
          r: '',
          zhou: '',
          yue: '',
          ji: '',
          nian: '',
          bitian: true
        }
      ],
      zhunze_dakai: false,
      xianshi_quanbu: false,
      xianshi_shezhi: false,
      xianshi_xiang_list: [],
      zhanshi_list: [
        {
          name: '客户',
          id: '1',
          zhi: false
        },
        {
          name: '拜访',
          id: '2',
          zhi: false
        },
        {
          name: '漏斗',
          id: '3',
          zhi: false
        },
        {
          name: '合同',
          id: '4',
          zhi: false
        },
        {
          name: '发票',
          id: '5',
          zhi: false
        },
        {
          name: '回款',
          id: '6',
          zhi: false
        },
        {
          name: '派工',
          id: '7',
          zhi: false
        }
      ],
      zhanshi_kehu: true,
      zhanshi_baifang: true,
      zhanshi_loudou: true,
      zhanshi_hetong: true,
      zhanshi_fapiao: true,
      zhanshi_huikuan: true,
      zhanshi_paigong: true,
      zhuguan_liyuan: false,
      bumen_zhuguan: false,
      kan_quanbu: false,
      danwei: '1' // 1元，2万元，3亿元
    }
  },
  created () {
    this.chaxun_bumen_zhuguan()
    this.jichu()
    // this.ceshi()
  },
  components: { txa, heade },
  mounted () {
        
  },
  watch: {
    bumen_renyuan_text (val) {
      this.$refs.kehu_bumen_ren_tree.filter(val)
    },
    chanpin_text (val) {
      this.$refs.kehu_chanpin_tree.filter(val)
    },
    dangqian_num () {
      this.dongtai_chaxun()// 这个是角色日周月季年的接口
    },
    xianshi_xiang_list: {
      handler (newValue, oldValue) {
        if (this.xianshi_xiang_list.length == this.zhanshi_list.length) {
          this.xianshi_quanbu = true
        } else {
          this.xianshi_quanbu = false
        }
      },
      deep: true
    }
    // suoxuan_list:{
    //     handler(val,oldval){
    //         this.dongtai_chaxun()
    //     },
    //     deep:true
    // }
  },
  methods: {
    ceshi () {
      const date = [
        // {
        //     label:'我是部门',//部门或人员的名字
        //     id:'id',//部门或人员的id
        //     target_mount:'3000',//总目标
        //     hasChildren:true,
        //     kehu:{     //客户--------------------------------
        //         xinzeng:'50',  //客户新增
        //         yi_fenpei_num:'40',  //客户已分配数量
        //         xinzeng_color:'1',  //客户新增     红绿点  1红，2黄，3绿
        //         yi_fenpei_color:'2',  //客户已分配数量   红绿点  1红，2黄，3绿
        //     },
        //     baifang:{  //拜访---------------------------------
        //         cishu:'3',  //拜访次数
        //         cishu_color:'1',  //拜访次数     红绿点  1红，2黄，3绿
        //         gongzuo_r:'5',  //拜访工作日
        //         shichang:'40',  //拜访时长
        //         shichang_color:'3',//拜访时长     红绿点  1红，2黄，3绿
        //     },
        //     loudou:{  //漏斗------------------------------------
        //         loudou_num:'3',  //漏斗数量
        //         loudou_mount:'40',  //漏斗金额
        //         loudou_mount_color:'2',  //漏斗金额     红绿点  1红，2黄，3绿
        //         zhesuan_mount:'50',  //漏斗折算金额
        //         zhesuan_mount_bl:'50',  //漏斗折算比率
        //         zhesuan_mount_bl_color:'1',  //漏斗折算比率     红绿点  1红，2黄，3绿
        //         zhuanhua_num:'40',  //漏斗转化数量
        //         zhuanhua_num_color:'1',  //漏斗转化数量     红绿点  1红，2黄，3绿
        //         zhuanhua_num_bl:'40',  //漏斗转化比率
        //         zhuanhua_mount:'400',  //漏斗转化金额
        //         zhuanhua_mount_color:'2',  //漏斗转化金额     红绿点  1红，2黄，3绿
        //         zhuanhua_bl:'20',  //漏斗转化比率
        //     },
        //     hetong:{  //合同--------------------------------------
        //         hetong_num:'20',  //合同数量
        //         hetong_mount:'300',  //合同金额
        //         hetong_mount_color:'1',  //合同金额     红绿点  1红，2黄，3绿
        //         wei_kaipiao_mount:'2700',  //未开票金额
        //         wei_kaipiao_mount_color:'1',  //未开票金额     红绿点  1红，2黄，3绿
        //     },
        //     fapiao:{  //发票--------------------------------------
        //         fapiao_mount:'1000',  //发票金额
        //         fapiao_mount_color:'2',  //发票金额     红绿点  1红，2黄，3绿
        //         yingshou_mount:'2000',  //发票应收金额
        //         yingshou_mount_color:'3',  //发票应收金额     红绿点  1红，2黄，3绿
        //     },
        //     huikuan:{  //回款-------------------------------------
        //         huikuan_mount:'2',  //回款金额
        //         huikuan_mount_color:'1'  //回款金额     红绿点  1红，2黄，3绿
        //     },
        //     paigong:{  //派工-------------------------------------
        //         paigong_times:'56',  //派工次数
        //         paigong_num:'23',  //派工人数
        //         paigong_times_color:'3'  //派工次数     红绿点  1红，2黄，3绿
        //     }
        // },
        // {
        //     label:'我是部门',//部门或人员的名字
        //     id:'id5',//部门或人员的id
        //     target_mount:'3000',//总目标
        //     kehu:{     //客户--------------------------------
        //         xinzeng:'50',  //客户新增
        //         yi_fenpei_num:'40',  //客户已分配数量
        //         xinzeng_color:'1',  //客户新增     红绿点  1红，2黄，3绿
        //         yi_fenpei_color:'2',  //客户已分配数量   红绿点  1红，2黄，3绿
        //     },
        //     baifang:{  //拜访---------------------------------
        //         cishu:'3',  //拜访次数
        //         cishu_color:'1',  //拜访次数     红绿点  1红，2黄，3绿
        //         gongzuo_r:'5',  //拜访工作日
        //         shichang:'40',  //拜访时长
        //         shichang_color:'3',//拜访时长     红绿点  1红，2黄，3绿
        //     },
        //     loudou:{  //漏斗------------------------------------
        //         loudou_num:'3',  //漏斗数量
        //         loudou_mount:'40',  //漏斗金额
        //         loudou_mount_color:'2',  //漏斗金额     红绿点  1红，2黄，3绿
        //         zhesuan_mount:'50',  //漏斗折算金额
        //         zhesuan_mount_bl:'50',  //漏斗折算比率
        //         zhesuan_mount_bl_color:'1',  //漏斗折算比率     红绿点  1红，2黄，3绿
        //         zhuanhua_num:'40',  //漏斗转化数量
        //         zhuanhua_num_color:'1',  //漏斗转化数量     红绿点  1红，2黄，3绿
        //         zhuanhua_num_bl:'40',  //漏斗转化比率
        //         zhuanhua_mount:'400',  //漏斗转化金额
        //         zhuanhua_mount_color:'2',  //漏斗转化金额     红绿点  1红，2黄，3绿
        //         zhuanhua_bl:'20',  //漏斗转化比率
        //     },
        //     hetong:{  //合同--------------------------------------
        //         hetong_num:'20',  //合同数量
        //         hetong_mount:'300',  //合同金额
        //         hetong_mount_color:'1',  //合同金额     红绿点  1红，2黄，3绿
        //         wei_kaipiao_mount:'2700',  //未开票金额
        //         wei_kaipiao_mount_color:'1',  //未开票金额     红绿点  1红，2黄，3绿
        //     },
        //     fapiao:{  //发票--------------------------------------
        //         fapiao_mount:'1500',  //发票金额
        //         fapiao_mount_color:'2',  //发票金额     红绿点  1红，2黄，3绿
        //         yingshou_mount:'800',  //发票应收金额
        //         yingshou_mount_color:'3',  //发票应收金额     红绿点  1红，2黄，3绿
        //     },
        //     huikuan:{  //回款-------------------------------------
        //         huikuan_mount:'400',  //回款金额
        //         huikuan_mount_color:'1'  //回款金额     红绿点  1红，2黄，3绿
        //     },
        //     paigong:{  //派工-------------------------------------
        //         paigong_times:'56',  //派工次数
        //         paigong_num:'23',  //派工人数
        //         paigong_times_color:'3'  //派工次数     红绿点  1红，2黄，3绿
        //     }
        // },
        {
          label: '2222', // 部门或人员的名字
          id: 'id', // 部门或人员的id
          aim_total: '5000', // 总目标
          hasChildren: true, // 是否有下级
          cust: { // 客户--------------------------------
            num_cust_add: '50', // 客户新增
            num_cust_alloted: '40', // 客户已分配数量
            status_add: '1', // 客户新增     红绿点  1红，2黄，3绿
            status_alloted: '2' // 客户已分配数量   红绿点  1红，2黄，3绿
          },
          visit: { // 拜访---------------------------------
            num_visit: '3', // 拜访次数
            status_num_visit: '1', // 拜访次数     红绿点  1红，2黄，3绿
            workday: '5', // 拜访工作日
            visit_long: '40', // 拜访时长
            status_visit_long: '3'// 拜访时长     红绿点  1红，2黄，3绿
          },
          funn: { // 漏斗------------------------------------
            num_funn: '3', // 漏斗数量
            amt_funn: '40', // 漏斗金额
            status_amt_funn: '2', // 漏斗金额     红绿点  1红，2黄，3绿
            amt_funn_disc: '50', // 漏斗折算金额
            rate_funn_disc: '50', // 漏斗折算比率
            status_rate_funn_disc: '1', // 漏斗折算比率     红绿点  1红，2黄，3绿
            num_funn_trsl: '40', // 漏斗转化数量
            status_num_funn_trsl: '1', // 漏斗转化数量     红绿点  1红，2黄，3绿
            rate_num_funn_trsl: '40', // 漏斗转化数量比率
            amt_funn_trsl: '400', // 漏斗转化金额
            status_amt_funn_trsl: '2', // 漏斗转化金额     红绿点  1红，2黄，3绿
            rate_amt_funn_trsl: '20' // 漏斗转化金额比率
          },
          con: { // 合同--------------------------------------
            num_con: '20', // 合同数量
            amt_con: '300', // 合同金额
            status_amt_con: '1', // 合同金额     红绿点  1红，2黄，3绿
            amt_un_inv: '2700', // 未开票金额
            status_amt_un_inv: '1' // 未开票金额     红绿点  1红，2黄，3绿
          },
          inv: { // 发票--------------------------------------
            amt_inv: '1000', // 发票金额
            status_amt_inv: '2', // 发票金额     红绿点  1红，2黄，3绿
            amt_un_rec: '2000', // 发票应收金额
            status_amt_un_rec: '3' // 发票应收金额     红绿点  1红，2黄，3绿
          },
          rec: { // 回款-------------------------------------
            amt_rec: '2', // 回款金额
            status_amt_rec: '1' // 回款金额     红绿点  1红，2黄，3绿
          },
          dis: { // 派工-------------------------------------
            num_dis: '56', // 派工次数
            num_dis_user: '23', // 派工人数
            status_num_dis: '3' // 派工次数     红绿点  1红，2黄，3绿
          }
        }
      ]
      date.forEach(item => {
        if (item.kehu != undefined) {
          this.kehu = true
        }
        if (item.baifang != undefined) {
          this.baifang = true
        }
        if (item.loudou != undefined) {
          this.loudou = true
        }
      })
      this.laogao_zhongji = date
    },
    // 基础加载文字
    jichu () {
      this.jiekou()
      // query_dept_staff_tree_aim_not({data:{
      //     ent_id:this.$ent_id(),
      //     user_id:this.$jichuxinxi().user_id
      // }}).then(res=>{
      //     console.log(res)
      //     if(res.data.code==200){
      //         let date=JSON.parse(res.data.body.data)
      //         console.log(date)
      //     }else if(res.data.code==500){}
      // })
            
      query_ent_prod_list_tree({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          // no_sale_prod_list({data:{
          //     ent_id:this.$ent_id(),
          //     user_id:this.$jichuxinxi().user_id,
          // }}).then(ras=>{
          //     this.xuanze_chanpin=true
          //     console.log(ras)
          //     let data=JSON.parse(ras.data.body.data)
                        
          //     this.$func.tickMenuIdFilter2().filter(date,data)
          //     console.log(date)
          //     this.chanpin_tree=date
          // })
          this.chanpin_tree = date
        } else if (res.data.code == 500) {}
      })
      // 角色规则
      query_role_board_rule({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          if (res.data.body != null) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            if (date != null) {
              this.neirong_list[0].r = date.num_cust_add != undefined ? date.num_cust_add : ''
              this.neirong_list[1].r = date.num_cust_alloted != undefined ? date.num_cust_alloted : ''
              this.neirong_list[4].r = date.amt_funn != undefined ? date.amt_funn : ''
              this.neirong_list[5].r = date.rate_funn_disc != undefined ? date.rate_funn_disc : ''
              this.neirong_list[6].r = date.num_funn_translate != undefined ? date.num_funn_translate : ''
              this.neirong_list[7].r = date.amt_funn_translate != undefined ? date.amt_funn_translate : ''
              this.neirong_list[10].r = date.rate_un_inv != undefined ? date.rate_un_inv : ''
              this.neirong_list[12].r = date.rate_un_rec != undefined ? date.rate_un_rec : ''
              for (let i = 1; i < this.biaotou_list.length; i++) {
                if (date.num_visit != undefined) {
                  this.neirong_list[2][this.biaotou_list[i].con] = date.num_visit.split(',')[i - 1]
                }
                if (date.single_visit_long != undefined) {
                  this.neirong_list[3][this.biaotou_list[i].con] = date.single_visit_long.split(',')[i - 1]
                }
                if (date.rate_con_complete != undefined) {
                  this.neirong_list[8][this.biaotou_list[i].con] = date.rate_con_complete.split(',')[i - 1]
                }
                if (date.rate_inv_complete != undefined) {
                  this.neirong_list[9][this.biaotou_list[i].con] = date.rate_inv_complete.split(',')[i - 1]
                }
                if (date.rate_rec_complete != undefined) {
                  this.neirong_list[11][this.biaotou_list[i].con] = date.rate_rec_complete.split(',')[i - 1]
                }
                if (date.num_dispatch != undefined) {
                  this.neirong_list[13][this.biaotou_list[i].con] = date.num_dispatch.split(',')[i - 1]
                }
              }
            }
          }
        } else if (res.data.code == 500) {}
      })
    },
    dongtai_chaxun () {
      const bumen_id = []
      const renyuan_id = []
      const chanpin_id = []
      this.suoxuan_bumen_list.forEach(item => {
        if (item.type == 2) {
          renyuan_id.push(item.id)
        }
        if (item.type == 1) {
          bumen_id.push(item.id)
        }
      })
      this.suoxuan_list.forEach(item => {
        if (item.type == 3) {
          chanpin_id.push(item.id)
        }
      })
      this.jiekou(null, bumen_id.length != 0 ? bumen_id.join(',') : null, renyuan_id.length != 0 ? renyuan_id.join(',') : null, chanpin_id.length != 0 ? chanpin_id.join(',') : null)
    },
    chaxun_bumen_zhuguan () {
      query_user_info({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          if (res.data.body.staff_level == 1) {
            this.zhuguan_liyuan = true
          } else {
            this.zhuguan_liyuan = false
          }
          if (res.data.body.dept_manager == 1) {
            this.bumen_zhuguan = true
          } else {
            this.bumen_zhuguan = false
          }
          if (res.data.body.all_module_read == 1) {
            this.kan_quanbu = true
          } else {
            this.kan_quanbu = false
          }
          query_dept_staff_tree_aim({
            data: {
              ent_id: this.$ent_id(),
              user_id: (this.zhuguan_liyuan || this.kan_quanbu) ? null : this.$jichuxinxi().user_id
            }
          }).then(zzjg => {
            console.log(zzjg)
            if (zzjg.data.code == 10168) {
              const data = JSON.parse(zzjg.data.body.data)
              console.log(data)
              this.bumen_renyuan_tree = data
            } else if (zzjg.data.code == 10169) {}
          })
        }
      })
    },
    // 这个是角色日周月季年的接口
    jiekou (parent_id, dept_ids, staff_ids, prod_ids) {
      generate_role_board({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          time_type: Number(this.dangqian_num) + 1 + '',
          dept_ids: dept_ids,
          prod_ids: prod_ids,
          staff_ids: staff_ids,
          parent_id: parent_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date[0] != null) {
            this.laogao_zhongji = []
            setTimeout(() => {
              this.laogao_zhongji = date
              date.forEach(item => {
                if (Number(item.aim_total) >= 100000000 ||
                                    Number(item.funn.amt_funn) >= 100000000 ||
                                    Number(item.funn.amt_funn_disc) >= 100000000 ||
                                    Number(item.funn.amt_funn_trsl) >= 100000000 ||
                                    Number(item.con.amt_con) >= 100000000 ||
                                    Number(item.con.amt_un_inv) >= 100000000 ||
                                    Number(item.inv.amt_inv) >= 100000000 ||
                                    Number(item.inv.amt_un_rec) >= 100000000 ||
                                    Number(item.rec.amt_rec) >= 100000000
                ) {
                  this.danwei = '3'
                } else if (Number(item.aim_total) >= 10000 ||
                                    Number(item.funn.amt_funn) >= 10000 ||
                                    Number(item.funn.amt_funn_disc) >= 10000 ||
                                    Number(item.funn.amt_funn_trsl) >= 10000 ||
                                    Number(item.con.amt_con) >= 10000 ||
                                    Number(item.con.amt_un_inv) >= 10000 ||
                                    Number(item.inv.amt_inv) >= 10000 ||
                                    Number(item.inv.amt_un_rec) >= 10000 ||
                                    Number(item.rec.amt_rec) >= 10000
                ) {
                  this.danwei = '2'
                }
              })
            }, 0)
          } else {
            this.laogao_zhongji = []
            this.danwei = '1'
          }
                    
          // date.roleBoardData.forEach(item=>{
          //     if(item.kehu!=undefined){
          //         this.kehu=true
          //     }
          //     if(item.baifang!=undefined){
          //         this.baifang=true
          //     }
          //     if(item.loudou!=undefined){
          //         this.loudou=true
          //     }
          // })
          // this.laogao_zhongji=date.roleBoardData
        } else if (res.data.code == 500) {}
      })
    },
    zhanshi_shuzi (num) {
      if (this.danwei == '3') {
        return (Number(num) / 100000000).toFixed(2)
      } else if (this.danwei == '2') {
        return (Number(num) / 10000).toFixed(2)
      } else {
        return Number(num).toFixed(0)
      }
    },
    // // 过滤部门树
    bumen_tree_guolv (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 过滤产品树
    chanpin_tree_guolv (value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    // 点击加载树形图下部分
    load (tree, treeNode, resolve) {
      // setTimeout(() => {
      //     resolve([
      //         {
      //             label:'2222',//部门或人员的名字
      //             id:'id2',//部门或人员的id
      //             aim_total:'5000',//总目标
      //             hasChildren:true,//是否有下级
      //             cust:{     //客户--------------------------------
      //                 num_cust_add:'50',  //客户新增
      //                 num_cust_alloted:'40',  //客户已分配数量
      //                 status_add:'1',  //客户新增     红绿点  1红，2黄，3绿
      //                 status_alloted:'2',  //客户已分配数量   红绿点  1红，2黄，3绿
      //             },
      //             visit:{  //拜访---------------------------------
      //                 num_visit:'3',  //拜访次数
      //                 status_num_visit:'1',  //拜访次数     红绿点  1红，2黄，3绿
      //                 workday:'5',  //拜访工作日
      //                 visit_long:'40',  //拜访时长
      //                 status_visit_long:'3',//拜访时长     红绿点  1红，2黄，3绿
      //             },
      //             funn:{  //漏斗------------------------------------
      //                 num_funn:'3',  //漏斗数量
      //                 amt_funn:'40',  //漏斗金额
      //                 status_amt_funn:'2',  //漏斗金额     红绿点  1红，2黄，3绿
      //                 amt_funn_disc:'50',  //漏斗折算金额
      //                 rate_funn_disc:'50',  //漏斗折算比率
      //                 status_rate_funn_disc:'1',  //漏斗折算比率     红绿点  1红，2黄，3绿
      //                 num_funn_trsl:'40',  //漏斗转化数量
      //                 status_num_funn_trsl:'1',  //漏斗转化数量     红绿点  1红，2黄，3绿
      //                 rate_funn_trsl:'40',  //漏斗转化比率
      //                 amt_funn_trsl:'400',  //漏斗转化金额
      //                 status_amt_funn_trsl:'2',  //漏斗转化金额     红绿点  1红，2黄，3绿
      //                 rate_fimm_trsl:'20',  //漏斗转化比率
      //             },
      //             con:{  //合同--------------------------------------
      //                 num_con:'20',  //合同数量
      //                 amt_con:'300',  //合同金额
      //                 status_amt_con:'1',  //合同金额     红绿点  1红，2黄，3绿
      //                 amt_un_inv:'2700',  //未开票金额
      //                 status_amt_un_inv:'1',  //未开票金额     红绿点  1红，2黄，3绿
      //             },
      //             inv:{  //发票--------------------------------------
      //                 amt_inv:'1000',  //发票金额
      //                 status_amt_inv:'2',  //发票金额     红绿点  1红，2黄，3绿
      //                 amt_un_rec:'2000',  //发票应收金额
      //                 status_amt_un_rec:'3',  //发票应收金额     红绿点  1红，2黄，3绿
      //             },
      //             rec:{  //回款-------------------------------------
      //                 amt_rec:'2',  //回款金额
      //                 status_amt_rec:'1'  //回款金额     红绿点  1红，2黄，3绿
      //             },
      //             dis:{  //派工-------------------------------------
      //                 num_dis:'56',  //派工次数
      //                 num_dis_user:'23',  //派工人数
      //                 status_num_dis:'3'  //派工次数     红绿点  1红，2黄，3绿
      //             }
      //         },
      //     ])
      // }, 200)
      const bumen_id = []
      const renyuan_id = []
      const chanpin_id = []
      this.suoxuan_bumen_list.forEach(item => {
        if (item.type == 2) {
          renyuan_id.push(item.id)
        }
        if (item.type == 1) {
          bumen_id.push(item.id)
        }
      })
      this.suoxuan_list.forEach(item => {
        if (item.type == 3) {
          chanpin_id.push(item.id)
        }
      })
      generate_role_board({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          time_type: Number(this.dangqian_num) + 1 + '',
          parent_id: tree.id,
          dept_ids: bumen_id.length != 0 ? bumen_id.join(',') : null,
          staff_ids: renyuan_id.length != 0 ? renyuan_id.join(',') : null,
          prod_ids: chanpin_id.length != 0 ? chanpin_id.join(',') : null
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          // if(date[0]!=null){
          resolve(date)
          // }else{
          // this.resolve(null)
          // }
                    
          // date.roleBoardData.forEach(item=>{
          //     if(item.kehu!=undefined){
          //         this.kehu=true
          //     }
          //     if(item.baifang!=undefined){
          //         this.baifang=true
          //     }
          //     if(item.loudou!=undefined){
          //         this.loudou=true
          //     }
          // })
          // this.laogao_zhongji=date.roleBoardData
        } else if (res.data.code == 500) {}
      })
    },
    dianji_chanpin_shu (val) {
      console.log(val)
      // this.suoxuan_list.forEach(item=>{
      //     if(val.id==item.id){

      //     }
      // })
      let shanchu = false
      for (let i = 0; i < this.suoxuan_list.length; i++) {
        if (this.suoxuan_list[i].id == val.id) {
          this.suoxuan_list.splice(i, 1)
          shanchu = true
          break
        }
      }
      setTimeout(() => {
        if (!shanchu) {
          this.suoxuan_list.push({
            label: val.label,
            id: val.id,
            type: val.type == 1 ? '3' : (val.type == 2 ? '3' : '')
          })
        }
      }, 0)
    },
    // // 点击部门树
    dianji_bumen_shu (val) {
      console.log(val)
      const list = this.$func.getSimpleCheckedNodes(this.$refs.kehu_bumen_ren_tree.store)
      console.log(list)
      this.suoxuan_bumen_list = list
    },
    dianji_shanchu_suoxuan (i) {
      // this.$refs.tree.setCheckedKeys([3]);
      // this.suoxuan_list.splice(index,1)
      const list = []
      this.suoxuan_list.forEach(item => {
        if (item.id != i.id) {
          list.push(item.id)
        }
      })
      setTimeout(() => {
        this.$refs.kehu_chanpin_tree.setCheckedKeys(list)
        // this.dongtai_chaxun()
      }, 0)
    },
    dianji_shanchu_bumen_suoxuan (i) {
      const list = []
      this.suoxuan_bumen_list.forEach(item => {
        if (item.id != i.id) {
          list.push(item.id)
        }
      })
      console.log(list)
      setTimeout(() => {
        this.$refs.kehu_bumen_ren_tree.setCheckedKeys(list)
        // this.dongtai_chaxun()
      }, 0)
    },
    biaotou (row) { // 根据报警级别显示颜色
      return 'color:#444;text-align:center;background:#f6f8f9;'
    },
    liebiao (row) {
      console.log(row)
      if (row.column.property == 'label') {
        return 'text-align:center;'
      } else {
        return 'text-align:right;padding:0;'
      }
    },
    // 显示设置   
    // 点击选择全部
    dianji_xuanze_quanbu () {
      this.xianshi_quanbu = !this.xianshi_quanbu
      if (this.xianshi_quanbu) {
        this.zhanshi_list.map((i, index) => { i.zhi = true })
        this.xianshi_xiang_list = []
        for (let i = 0; i < this.zhanshi_list.length; i++) {
          this.xianshi_xiang_list.push(this.zhanshi_list[i])
        }
      } else {
        this.zhanshi_list.map((i, index) => { i.zhi = false })
        this.xianshi_xiang_list = []
      }
    },
    xuanze_danxuan (i) {
      i.zhi = !i.zhi
      if (i.zhi) {
        this.xianshi_xiang_list.push(i)
      } else {
        for (let a = 0; a < this.xianshi_xiang_list.length; a++) {
          if (this.xianshi_xiang_list[a].id == i.id) {
            this.xianshi_xiang_list.splice(a, 1)
          }
        }
      }
    },
    dianji_shanchu_xianshi (i, index) {
      this.zhanshi_list.map((item, idx) => {
        if (item.id == i.id) {
          item.zhi = false
        }
      })
      this.xianshi_xiang_list.splice(index, 1)
    },
    dianji_lie_queding () {
      let kehu = false
      let baifang = false
      let loudou = false
      let hetong = false
      let fapiao = false
      let huikuan = false
      let paigong = false
      this.xianshi_xiang_list.forEach(item => {
        if (item.zhi) {
          if (item.id == 1) {
            kehu = true
          }
          if (item.id == 2) {
            baifang = true
          }
          if (item.id == 3) {
            loudou = true
          }
          if (item.id == 4) {
            hetong = true
          }
          if (item.id == 5) {
            fapiao = true
          }
          if (item.id == 6) {
            huikuan = true
          }
          if (item.id == 7) {
            paigong = true
          }
        }
      })
      this.zhanshi_kehu = kehu
      this.zhanshi_baifang = baifang
      this.zhanshi_loudou = loudou
      this.zhanshi_hetong = hetong
      this.zhanshi_fapiao = fapiao
      this.zhanshi_huikuan = huikuan
      this.zhanshi_paigong = paigong
      this.xianshi_xiang_list = []
      this.xianshi_shezhi = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './gongzuo.scss';
</style>
