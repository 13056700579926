<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <header class="gongzuohead">
        <div class="gongzuohead_box">
            <div class="gongzuohead_box_left" @click="jin_shouye">
                <img src="../../assets/wai_daohang/logo_wai.png" alt="">
                <div></div>
                我的工作台
            </div>
            <ul class="gongzuohead_box_right">
                <li v-for="(i,index) in list" :class="path==i.path?'xuanzhong':''" :key="index" @click="dianji_tiaozhuan(i)">
                    <div :style="path==i.path?i.style:''"></div>
                    {{i.name}}
                </li>
            </ul>
        </div>
    </header>
</template>

<script>
// import { } from '../api/api.js'
export default {
  name: 'gongzuohead',
  data () {
    return {
      list: [
        {
          name: '首页',
          path: '/home',
          style: 'background: url(' + require('@/assets/me/home_bai.png') + ') no-repeat center;background-size:0.14rem 0.18rem;'
        },
        {
          name: '角色看板',
          path: '/rolekanban',
          style: 'background: url(' + require('@/assets/me/kanban_bai.png') + ') no-repeat center;background-size:0.21rem 0.21rem;'
        },
        {
          name: '审批中心',
          path: '/initiateapp',
          style: 'background: url(' + require('@/assets/me/shenpi_bai.png') + ') no-repeat center;background-size:0.22rem 0.22rem;'
        },
        {
          name: '消息中心',
          path: '/home',
          style: 'background: url(' + require('@/assets/me/xiaoxi_bai.png') + ') no-repeat center;background-size: 0.22rem 0.18rem;'
        },
        {
          name: '数据管理',
          path: '/home',
          style: 'background: url(' + require('@/assets/me/shuju_bai.png') + ') no-repeat center;background-size:0.18rem 0.18rem;'
        }
      ],
      path: ''
    }
  },
  created () {
    this.jichu()
    this.lujing(this.$route.path)
    // if(this.qiye_dengji!=1&&this.qiye_dengji.length!=0){
    //   this.$router.push('/install')
    //   sessionStorage.setItem("pc_pc_title", '0');
    // }
  },
  mounted () {
        
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.lujing(val.fullPath)
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    lujing (path) {
      this.path = path
    },
    // 基础信息
    jichu () {

    },
    dianji_tiaozhuan (i) {
      if (this.path != i.path) {
        this.$router.push(i.path)
      }
    },
    jin_shouye () {
      this.$router.push('/home')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './gongzuo.scss';
</style>
