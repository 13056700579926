<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="txa">
        <div class="touxiang">
          <img v-if="jichuxinxi.avatar!=undefined" :src="jichuxinxi.avatar" alt="">
          <p class="touxiang_ph" v-if="jichuxinxi.avatar==undefined">{{jichuxinxi.name!=undefined?jichuxinxi.name[0]:name}}</p>
          <p class="qiye_name" v-if="jichuxinxi.ent_info!=undefined">{{jichuxinxi.ent_info.ent_name}}</p>
          <i class="el-icon-caret-left icon-left"></i>
          <i class="el-icon-caret-bottom icon-bottom"></i>
          <div class="touxiang_box">
            <div class="touxiang_box_tit">
              <img class="touxiang_icon" v-if="jichuxinxi.avatar!=undefined" :src="jichuxinxi.avatar" alt="">
              <p class="touxiang_text" v-if="jichuxinxi.avatar==undefined">{{jichuxinxi.name!=undefined?jichuxinxi.name[0]:name}}</p>
              <div class="touxiang_box_tit_right">
                <p>{{jichuxinxi.name!=undefined?jichuxinxi.name:''}}</p>
                <div class="touxiang_box_tit_right_gongsi" @click="qiye_dakai=!qiye_dakai">
                  <p>{{jichuxinxi.ent_info!=undefined?jichuxinxi.ent_info.ent_name:''}}</p>
                  <i :class="qiye_dakai?'el-icon-caret-bottom':'el-icon-caret-left'"></i>
                </div>
              </div>
            </div>
            <ul class="touxiang_box_bottom">
              <li @click="jin_gongzuo_tai">
                <div class="ic-icon"></div>
                <p>我的工作台</p>
              </li>
              <li>
                <div class="ic-icon"></div>
                <p>消息中心</p>
              </li>
              <li @click="jin_wode">
                <div class="ic-icon"></div>
                <p>设置中心</p>
              </li>
              <li @click="out">
                <div class="ic-icon"></div>
                <p>退出登录</p>
              </li>
            </ul>
          </div>
          
          <!-- <div>
            <p class="qiye_name" v-if="jichuxinxi.ent_info!=undefined">{{jichuxinxi.ent_info.ent_name}}</p>
            <p class="geren_name" v-if="jichuxinxi.name!=undefined">{{jichuxinxi.name}}</p>
          </div> -->
        </div>
    </div>
</template>

<script>
import { logout } from '../api/api.js'
export default {
  name: 'txa',
  data () {
    return {
      name: '智',
      jichuxinxi: '',
      datea: '',
      gongsi_name: '',
      qiye_dakai: false
    }
  },
  mounted () {
  },
  created () {
    this.jichu()
  },
  methods: {
    jin_wode () {
      this.$router.push('/me')
    },
    // 基础
    jichu () {
      // this.quanxian=this.$func.quanxian()
      // if(this.quanxian!=='0'){
      //   this.quanxian.map(item=>{this['module_'+item.module_id]=true})
      // }
      this.jichuxinxi = this.$jichuxinxi()
      if (sessionStorage.getItem('pc_mlbb_ent_flag') != null && sessionStorage.getItem('pc_mlbb_ent_flag') != undefined && sessionStorage.getItem('pc_mlbb_ent_flag') != '') {
        this.datea = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_flag'))
      }
      if (this.datea == 1) {
        this.gongsi_name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
      } else {
        this.gongsi_name = ''
      }
    },
    out () {
      logout({
        data: {
          mobile: this.jichuxinxi.mobile
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10015) {
          this.$message({
            message: '退出成功',
            type: 'success'
          })
          sessionStorage.clear()
          // sessionStorage.setItem("pc_pc_wai_daohang", JSON.stringify(0));
          this.$router.push('/')
        } else if (res.data.code == 10016) {
          this.$message({
            message: '退出失败',
            type: 'error'
          }) 
        } else if (res.data.code == 11000) {
          // this.$message({
          //     message: '您未登录',
          //     type: 'error'
          // }); 
          this.$message({
            message: '退出成功',
            type: 'success'
          })
          sessionStorage.clear()
          // sessionStorage.setItem("pc_pc_wai_daohang", JSON.stringify(0));
          this.$router.push('/')
        }
      })
    },
    jin_gongzuo_tai () {
      if (this.$jichuxinxi().ent_info != undefined && this.$jichuxinxi().ent_info.ent_name.length != 0) {
        this.$router.push('/rolekanban')
      } else {
        this.$message({
          message: '您当前没有企业',
          type: 'warning'
        }) 
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.txa{
    position: fixed;
    width: 100%;
    z-index: 100;
    .touxiang{
      position: absolute;
      right:0.5rem;
      top:0.19rem;
      height:0.36rem;
      width: 2.3rem;
      display: flex;
      align-items: center;
      justify-content:space-between;
      padding-bottom: 0.06rem;
      cursor: pointer;
      img{
        height:0.36rem;
        width: 0.36rem;
        border-radius: 50%;
        margin-right: 0.07rem;
      }
      .touxiang_ph{
        height:0.36rem;
        width: 0.36rem;
        border-radius: 50%;
        margin-right: 0.04rem;
        border-radius: 50%;
        line-height: 0.36rem;
        background: #d2d2d2;
        color:#444;
        font-size: 0.2rem;
        text-align: center;
        font-family: NSimSun;
      }
      .icon-left{
        color:#c4c7cd;
        font-size: 0.1rem;
      }
      .icon-bottom{
        display: none;
        color:#c4c7cd;
        font-size: 0.1rem;
      }
      &:hover{
        .icon-left{
          display: none;
        }
        .icon-bottom{
          display: block;
        }
        .touxiang_box{
          display: block;
        }
      }
      .touxiang_box{
        height:1.4rem;
        width:2.8rem;
        background:#fff;
        border:0.01rem solid #f6f3f9;
        position: absolute;
        top: 0.42rem;
        right:0;
        padding: 0.24rem 0.19rem;
        display: none;
        .touxiang_box_tit{
          display: flex;
          align-items: center;
          padding-bottom: 0.25rem;
          border-bottom: 0.01rem solid #e6e9ee;
          .touxiang_icon{
            margin-right: 0.1rem;
            height:0.4rem;
            width: 0.4rem;
          }
          .touxiang_text{
            height:0.4rem;
            width: 0.4rem;
            border-radius: 50%;
            margin-right: 0.1rem;
            border-radius: 50%;
            line-height: 0.4rem;
            background: #d2d2d2;
            color:#444;
            font-size: 0.18rem;
            text-align: center;
            font-family: NSimSun;
          }
          .touxiang_box_tit_right{
            p{
              font-size: 0.12rem;
              width:1.5rem;
              color:#666;
              overflow: hidden;/*超出部分隐藏*/
              white-space: nowrap;/*不换行*/
              text-overflow:ellipsis;/*超出部分文字以...显示*/
              &:nth-child(1){
                margin-bottom: 0.05rem;
              }
            }
            .touxiang_box_tit_right_gongsi{
              display: flex;
              align-items: center;
              justify-content: space-between;
              p{
                margin: 0;
              }
              i{
                color:#c4c7cd;
                font-size: 0.06rem;
              }
            }
          }
        }
        .touxiang_box_bottom{
          margin-top: 0.25rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          li{
            &:nth-child(1){
              .ic-icon{
                background: url('../assets/me/gongzuo_hui.png') no-repeat center;
                background-size:0.3rem 0.26rem;
              }
              &:hover{
                .ic-icon{
                  background: url('../assets/me/gongzuo_zi.png') no-repeat center;
                  background-size:0.3rem 0.26rem;
                }
              }
            }
            &:nth-child(2){
              .ic-icon{
                background: url('../assets/me/xiaoxi_hui.png') no-repeat center;
                background-size: 0.3rem 0.22rem;
              }
              &:hover{
                .ic-icon{
                  background: url('../assets/me/xiaoxi_zi.png') no-repeat center;
                  background-size: 0.3rem 0.22rem;
                }
              }
            }
            &:nth-child(3){
              .ic-icon{
                background: url('../assets/me/shezhi_hui.png') no-repeat center;
                background-size:0.26rem 0.26rem;
              }
              &:hover{
                .ic-icon{
                  background: url('../assets/me/shezhi_zi.png') no-repeat center;
                  background-size:0.26rem 0.26rem;
                }
              }
            }
            &:nth-child(4){
              .ic-icon{
                background: url('../assets/me/tuichu_hui.png') no-repeat center;
                background-size:0.24rem 0.25rem;
              }
              &:hover{
                .ic-icon{
                  background: url('../assets/me/tuichu_zi.png') no-repeat center;
                  background-size:0.24rem 0.25rem;
                }
              }
            }
            .ic-icon{
              margin: 0 auto;
              height:0.26rem;
              width:0.3rem;
              margin-bottom: 0.12rem;
            }
            p{
              font-size: 0.12rem;
              color:#666;
            }
            &:hover{
              p{
                color:#9a86db;
              }
            }
          }
        }
      }
      .qiye_name{
        width:1.8rem;
        font-size: 0.16rem;
        color:#444;
        overflow: hidden;/*超出部分隐藏*/
        white-space: nowrap;/*不换行*/
        text-overflow:ellipsis;/*超出部分文字以...显示*/
        line-height: 0.38rem;
      }
      // div{
      //   .qiye_name{
      //     width:1.8rem;
      //     font-size: 0.24rem;
      //     color:#fff;
      //     overflow: hidden;/*超出部分隐藏*/
      //     white-space: nowrap;/*不换行*/
      //     text-overflow:ellipsis;/*超出部分文字以...显示*/
      //     line-height: 0.38rem;
      //   }
      //   .geren_name{
      //     width:1.8rem;
      //     font-size: 0.2rem;
      //     color:#fff;
      //     overflow: hidden;/*超出部分隐藏*/
      //     white-space: nowrap;/*不换行*/
      //     text-overflow:ellipsis;/*超出部分文字以...显示*/
      //   }
      // }
    }
}
</style>
